import { NgModule } from '@angular/core';
import {NgxTributeDirective} from './ngx-tribute.directive';

@NgModule({
  imports: [
  ],
  declarations: [NgxTributeDirective],
  exports: [NgxTributeDirective]
})
export class NgxTributeModule { }
